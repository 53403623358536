import React from 'react'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { DarkTheme } from './Themes';
import { useNavigate } from 'react-router-dom';

import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import PowerButton from '../subComponents/PowerButton';
import ParticleComponent from '../subComponents/ParticleComponent';
import BigTitle from '../subComponents/BigTitlte';
import astronaut from '../assets/Images/spaceman.png';

const Box = styled.div`
background-color: ${props => props.theme.body};
width: 100vw;
height:100vh;
position: relative;
overflow: hidden;
`;

const float = keyframes`
0% { transform: translateY(-10px) }
50% { transform: translateY(15px) translateX(15px) }
100% { transform: translateY(-10px) }
`;

const Spaceman = styled.div`
position: absolute;
top: 10%;
right: 5%;
width: 20vw;
animation: ${float} 4s ease infinite;
img{
    width: 100%;
    height: auto;
}
`;



const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  padding: 2rem;
  width: 50vw;
  height: 60vh;
  z-index: 3;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(0.6rem + 1vw);
  backdrop-filter: blur(4px);
  
  position: absolute;
  left: calc(5rem + 5vw);
  top: 10rem;
  font-family: 'Ubuntu Mono', monospace;
  font-style: italic;
`;

const CloseButton = styled.button`
position: absolute;
top: 1rem;
right: 1rem;
background-color: transparent;
color: ${(props) => props.theme.text};
border: none;
font-size: 1.5rem;
cursor: pointer;
z-index: 10;
transition: transform 0.2s ease;

&:hover {
    transform: scale(1.2);
}
`;

const AboutPage = () => {
    const navigate = useNavigate(); // Hook for navigation

    return (
        <ThemeProvider theme={DarkTheme}>
            <Box>
                <LogoComponent theme='dark' />
                <SocialIcons theme='dark' />
                <PowerButton />
                <ParticleComponent theme='dark' />

                <CloseButton onClick={() => navigate('/', { state: { click: true } })}>
                    &times;
                </CloseButton>

                <Spaceman>
                    <img src={astronaut} alt="spaceman" />
                </Spaceman>
                <Main>
                    We are Full Stack Web and Mobile developers located in India. We love to create simple yet beautiful websites, apps with great user experience.
                    <br /> <br />
                    We specialize in frontend and backend development, constantly exploring new technologies to build innovative and impactful projects.
                    <br /> <br />
                    We believe that everything becomes art when approached with care and consciousness. Connect with us through our social links for insights.
                </Main>

                <BigTitle text="ABOUT" top="10%" left="5%" />
            </Box>
        </ThemeProvider>
    );
};

export default AboutPage;
